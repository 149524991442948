import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { IPayload } from '@quesmed/types-rn/models';
import { shallow } from 'zustand/shallow';

import { localStorageTyped, setSentryContext, setSentryTag } from 'utils';
import isTokenValid from './isTokenValid';
import { logoutUser } from './logoutUser';
import { authState, useAuth } from './useAuth';
import { useShouldRenewToken } from './useShouldRenewToken';
import { version } from '../../package.json';

const { getItem: getStorageToken, setItem: setStorageToken } =
  localStorageTyped<string>('usertoken');

export const useDemo = () => authState(state => state, shallow).isDemo;

export const AuthController = (): null => {
  const {
    token,
    setToken,
    setIsAuthPending,
    setTokenDecoded,
    setIsAuthenticated,
  } = useAuth();
  useShouldRenewToken();

  useEffect(() => {
    const storageToken = getStorageToken();

    if (storageToken) {
      setToken(storageToken);
    } else {
      setIsAuthPending(false);
    }
  }, [setIsAuthPending, setToken]);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode<IPayload>(token);

      if (isTokenValid(decodedToken)) {
        setStorageToken(token);
        setTokenDecoded(decodedToken);
        setIsAuthenticated(true);
        setSentryContext('user', {
          email: decodedToken.username,
          id: decodedToken.id,
        });
        setSentryTag('app_version', version);
      } else {
        logoutUser();
      }

      setIsAuthPending(false);
    }
  }, [token, setIsAuthPending, setTokenDecoded, setIsAuthenticated]);

  useEffect(() => {
    const handleStorageChange = () => {
      const storageToken = getStorageToken();

      if (!storageToken) {
        logoutUser({
          local: true,
        });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return null;
};
