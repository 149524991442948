import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

interface NewVersionState {
  isNewVersion: boolean;
  setIsNewVersion: (valeu: boolean) => void;
}

const newVersionState = create<NewVersionState>()(set => ({
  isNewVersion: false,
  setIsNewVersion: isNewVersion => set({ isNewVersion }),
}));

const useNewVersionState = () =>
  newVersionState(
    useShallow(({ isNewVersion, setIsNewVersion }) => ({
      isNewVersion,
      setIsNewVersion,
    }))
  );

export default useNewVersionState;
