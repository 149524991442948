import { useCallback, useEffect, useMemo } from 'react';

import useLeaveExerciseBlockade from '../useLeaveExerciseBlockade';
import useLeaveMarksheet from './useLeaveMarksheet';
import { paths } from 'Router';
import { isDev } from 'config/constants';
import useNewVersionState from '../useNewVersionState';

const { questions, mockTests } = paths;
const { root, groupLobby } = questions;

const SOLO_ROUTES = [`${root}/solo/quiz`, root];
const GROUP_ROUTES = [
  `${root}/${groupLobby}?join=`,
  `${root}/${groupLobby}/quiz/builder`,
  `${root}/${groupLobby}/quiz/quiz`,
  `${root}/${groupLobby}/quiz/summary`,
];

const MOCK_TESTS_ROUTES = [`${mockTests.root}/test`];

interface QuestionLeavingBlockade {
  ignoreBlockade: boolean;
  shouldBlock: boolean;
  marksheetId?: number;
  solo?: boolean;
  mockTest?: boolean;
}

const getBlockadeRoutes = (mockTest?: boolean, solo?: boolean) => {
  if (mockTest) {
    return MOCK_TESTS_ROUTES;
  }

  return solo ? SOLO_ROUTES : GROUP_ROUTES;
};

const useMarksheetLeavingBlockade = ({
  marksheetId,
  mockTest,
  ignoreBlockade,
  shouldBlock,
  solo,
}: QuestionLeavingBlockade) => {
  const { leaveOnClose } = useLeaveMarksheet(marksheetId);
  const { isNewVersion } = useNewVersionState();

  const routes = useMemo(
    () => getBlockadeRoutes(mockTest, solo),
    [mockTest, solo]
  );

  const ignorePath = useCallback(
    (path: string) =>
      Boolean(marksheetId && routes.some(route => path.includes(route))),
    [marksheetId, routes]
  );

  const handleLeaveOnClose = useCallback(() => {
    if (!ignoreBlockade) {
      leaveOnClose();
    }

    return undefined;
  }, [ignoreBlockade, leaveOnClose]);

  useLeaveExerciseBlockade({
    ignorePath,
    ignoreBlockade,
    shouldBlock,
  });

  useEffect(() => {
    if (solo && !isDev && !isNewVersion) {
      window.addEventListener('beforeunload', handleLeaveOnClose);

      return () => {
        window.removeEventListener('beforeunload', handleLeaveOnClose);
      };
    }
  }, [handleLeaveOnClose, solo, isNewVersion]);
};

export default useMarksheetLeavingBlockade;
