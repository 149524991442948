import React from 'react';
import Bowser from 'bowser';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { alpha } from 'utils';
import { Body, H5 } from 'components/Typography';

const Overlay = styled(Box)(({ theme: { spacing } }) => ({
  // dark for all themes
  backgroundColor: alpha('#3e414a', 0.6),
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 999999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: spacing(4),
}));

const OverlayBody = styled(Box)(({ theme: { spacing } }) => ({
  backgroundColor: '#3e414a',
  padding: spacing(8),
  borderRadius: '10px',

  '& h5': {
    marginBottom: spacing(4),
  },
}));

const BrowserVersionController = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isInvalidBrowser = browser.satisfies({
    chrome: '<100',
    firefox: '<125',
    opera: '<100',
    safari: '<16',
  });

  return isInvalidBrowser ? (
    <Overlay>
      <OverlayBody>
        <H5>Outdated browser version</H5>
        <Body>You are using an outdated browser version</Body>
        <Body>Please update your browser or use other up to date browser</Body>
        <Body>(we recommend Google Chrome)</Body>
      </OverlayBody>
    </Overlay>
  ) : null;
};

export default BrowserVersionController;
