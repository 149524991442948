import { createTheme } from '@mui/material/styles';
import { alpha } from '@mui/material';

import {
  BLACK,
  BREAKPOINTS,
  COLORS,
  DARK_BACKGROUND,
  ELEVATION,
  FONT_FAMILY,
  MIXINS,
  TRANSPARENCY,
  TYPOGRAPHY,
  WHITE,
} from './styleConstants';

export const lightTheme = createTheme({
  breakpoints: { ...BREAKPOINTS },
  spacing: 4,
  shape: {
    borderRadius: 7,
  },
  mixins: { ...MIXINS },
  palette: {
    ...COLORS,
    mode: 'light',
    primary: {
      dark: COLORS.innovativeBlue[600],
      light: COLORS.innovativeBlue[200],
      extraLight: COLORS.innovativeBlue[50],
      main: COLORS.innovativeBlue[500],
      contrastText: WHITE,
    },
    secondary: {
      dark: COLORS.strongBlack[900],
      light: COLORS.strongBlack[300],
      extraLight: COLORS.strongBlack[100],
      main: COLORS.strongBlack[700],
      contrastText: WHITE,
    },
    error: {
      dark: COLORS.vitalityRed[900],
      light: COLORS.vitalityRed[400],
      extraLight: COLORS.vitalityRed[50],
      main: COLORS.vitalityRed[500],
      contrastText: WHITE,
    },
    warning: {
      dark: COLORS.amber[800],
      light: COLORS.amber[300],
      main: COLORS.amber[500],
      extraLight: COLORS.amber[50],
      contrastText: COLORS.strongBlack[900],
    },
    success: {
      dark: COLORS.reliableGreen[600],
      light: COLORS.reliableGreen[300],
      extraLight: COLORS.reliableGreen[50],
      main: COLORS.reliableGreen[500],
      contrastText: WHITE,
    },
    background: {
      paper: WHITE,
      default: COLORS.strongBlack[50],
    },
    text: {
      primary: COLORS.strongBlack[900],
      secondary: COLORS.strongBlack[700],
      disabled: COLORS.strongBlack[500],
    },
    mockTest: {
      main: '#2E6BA5',
      light: '#6B97D4',
      contrastText: WHITE,
    },
    contrast: {
      main: COLORS.strongBlack[900],
      dark: COLORS.strongBlack[700],
      light: COLORS.strongBlack[500],
      contrastText: WHITE,
    },
    icon: {
      dark: COLORS.strongBlack[900],
      light: COLORS.strongBlack[400],
      main: COLORS.strongBlack[600],
      contrastText: WHITE,
    },
    stroke: {
      dark: COLORS.strongBlack[900],
      light: COLORS.strongBlack[100],
      main: COLORS.strongBlack[200],
    },
    toggle: {
      dark: COLORS.strongBlack[900],
      light: COLORS.strongBlack[100],
      main: COLORS.strongBlack[700],
      contrastText: WHITE,
      contrastTextDark: COLORS.strongBlack[900],
    },
    chart: {
      pink: COLORS.brainPink[100],
      greenLight: COLORS.encouragingLime[500],
      background: COLORS.strongBlack[100],
      foreground: COLORS.brainPink[300],
      main: COLORS.vitalityRed[300],
      successLight: COLORS.reliableGreen[100],
      errorLight: COLORS.vitalityRed[100],
    },
    tooltip: {
      fontColor: WHITE,
    },
    dashboardWidget: {
      flashcardsBackground: COLORS.brainPink[100],
      flashcardsColor: COLORS.vitalityRed[300],
      heatmap: COLORS.amber[50],
      heatmapColor: COLORS.amber[500],
      defaultBackground: COLORS.innovativeBlue[50],
      defaultColor: COLORS.innovativeBlue[500],
    },
    opacity: {
      gradient: TRANSPARENCY,
      panelItem: alpha(COLORS.strongBlack[100], 0.5),
    },
    disabled: COLORS.strongBlack[300],
    products: {
      // TODO: When colours are finalized, const them in styleConstants
      CM_UKMLA: {
        light: COLORS.encouragingLime[500],
        primary: '#1AC89E',
        dark: COLORS.reliableGreen[500],
        secondary: COLORS.encouragingLime[500],
        background: COLORS.reliableGreen[50],
        contrast: BLACK,
      },
      MRCP: {
        extraLight: COLORS.calmingBlue[500],
        primary: COLORS.innovativeBlue[500],
        light: COLORS.innovativeBlue[300],
        background: COLORS.innovativeBlue[50],
        secondary: COLORS.innovativeBlue[200],
        tertiary: '#C3E1EB',
        contrast: BLACK,
        main: COLORS.innovativeBlue[500],
        supplementaryLight: '#C5E5EE',
      },
      MSRA: {
        primary: '#7551DC',
        light: '#C8A7FF',
        extraLight: '#DBDEFF',
        contrast: BLACK,
        background: '#F8F4FF',
      },
      AMS: {
        extraLight: '#FFC8F0',
        light: COLORS.brainPink[500],
        primary: '#EF9388',
        background: '#FDF7F6',
        contrast: BLACK,
        pink: '#FFC8F0',
        secondary: '#EF9388',
        tertiary: '#F2C2BC',
      },
      INTERVIEW: {
        extraLight: '#F4CFBF',
        light: '#F2BD6C',
        primary: '#F1894E',
        background: '#FBF8EF',
        contrast: BLACK,
        secondary: '#F4CFBF',
        tertiary: '#F1894E',
      },
      PLAB: {
        extraLight: '#F2C9EA',
        light: '#D194EA',
        primary: '#B35B9E',
        background: '#FAF4FD',
        contrast: BLACK,
      },
      inactive: {
        light: COLORS.strongBlack[200],
        primary: COLORS.strongBlack[400],
      },
    },
    calculator: {
      borderColor: COLORS.innovativeBlue[50],
      result: COLORS.innovativeBlue[50],
      numbers: WHITE,
      numbersHover: COLORS.strongBlack[100],
      numbersActive: COLORS.strongBlack[200],
      functions: COLORS.innovativeBlue[500],
      functionsHover: COLORS.innovativeBlue[400],
      functionsActive: COLORS.innovativeBlue[600],
      utils: COLORS.innovativeBlue[200],
      utilsHover: COLORS.innovativeBlue[100],
      utilsActive: COLORS.innovativeBlue[300],
      contrastText: COLORS.strongBlack[900],
      contrastFunctionsText: WHITE,
    },
    image: {
      main: BLACK,
    },
  },
  typography: { ...TYPOGRAPHY },
  components: {
    MuiCssBaseline: {
      styleOverrides: FONT_FAMILY,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
  },
  elevation: { ...ELEVATION },
});

export const darkTheme = createTheme({
  breakpoints: { ...BREAKPOINTS },
  shape: {
    borderRadius: 7,
  },
  spacing: 4,
  mixins: { ...MIXINS },
  palette: {
    ...COLORS,
    mode: 'dark',
    primary: {
      dark: COLORS.innovativeBlue[600],
      light: COLORS.innovativeBlue[200],
      extraLight: alpha(COLORS.innovativeBlue[500], 0.15),
      main: COLORS.innovativeBlue[500],
      contrastText: WHITE,
    },
    secondary: {
      dark: COLORS.strongBlack[50],
      light: COLORS.strongBlack[600],
      extraLight: COLORS.strongBlack[400],
      main: COLORS.strongBlack[300],
      contrastText: COLORS.strongBlack[900],
    },
    error: {
      dark: COLORS.vitalityRed[500],
      light: COLORS.vitalityRed[300],
      extraLight: alpha(COLORS.vitalityRed[500], 0.15),
      main: COLORS.vitalityRed[400],
      contrastText: WHITE,
    },
    warning: {
      dark: COLORS.amber[600],
      light: COLORS.amber[200],
      main: COLORS.amber[400],
      extraLight: alpha(COLORS.amber[500], 0.15),
      contrastText: WHITE,
    },
    success: {
      dark: COLORS.reliableGreen[500],
      light: COLORS.reliableGreen[300],
      extraLight: alpha(COLORS.reliableGreen[500], 0.15),
      main: COLORS.reliableGreen[400],
      contrastText: WHITE,
    },
    background: {
      paper: DARK_BACKGROUND,
      default: COLORS.strongBlack[900],
    },
    text: {
      primary: COLORS.strongBlack[50],
      secondary: COLORS.strongBlack[400],
      disabled: COLORS.strongBlack[600],
    },
    mockTest: {
      main: '#2E6BA5',
      light: '#6B97D4',
      contrastText: WHITE,
    },
    contrast: {
      main: COLORS.strongBlack[50],
      dark: COLORS.strongBlack[400],
      light: COLORS.strongBlack[600],
      contrastText: COLORS.strongBlack[900],
    },
    icon: {
      dark: COLORS.strongBlack[200],
      light: COLORS.strongBlack[700],
      main: COLORS.strongBlack[500],
      contrastText: BLACK,
    },
    stroke: {
      dark: COLORS.strongBlack[500],
      light: COLORS.strongBlack[200],
      main: COLORS.strongBlack[800],
    },
    toggle: {
      dark: COLORS.strongBlack[100],
      light: COLORS.strongBlack[800],
      main: COLORS.strongBlack[600],
      contrastText: COLORS.strongBlack[900],
      contrastTextDark: WHITE,
    },
    chart: {
      // TODO: Update for dark theme
      greenLight: COLORS.encouragingLime[500],
      pink: COLORS.brainPink[100],
      background: COLORS.strongBlack[100],
      foreground: COLORS.brainPink[300],
      main: COLORS.vitalityRed[300],
      successLight: COLORS.reliableGreen[100],
      errorLight: COLORS.vitalityRed[100],
    },
    tooltip: {
      fontColor: COLORS.strongBlack[700],
    },
    dashboardWidget: {
      // TODO: Update for dark theme
      flashcardsBackground: COLORS.brainPink[100],
      flashcardsColor: COLORS.vitalityRed[300],
      heatmap: COLORS.amber[50],
      heatmapColor: COLORS.amber[500],
      defaultBackground: COLORS.innovativeBlue[50],
      defaultColor: COLORS.innovativeBlue[500],
    },
    opacity: {
      gradient: TRANSPARENCY,
      panelItem: alpha(COLORS.strongBlack[100], 0.5),
    },
    disabled: COLORS.strongBlack[600],
    products: {
      CM_UKMLA: {
        light: '#CAF18E',
        primary: '#17B58F',
        dark: COLORS.reliableGreen[500],
        background: '#182F2C',
        secondary: '#A6CF66',
        contrast: COLORS.strongBlack[50],
      },
      MRCP: {
        light: '#85A4FF',
        primary: COLORS.innovativeBlue[500],
        extraLight: '#B4DDE9',
        background: '#1E2436',
        secondary: '#97B2FF',
        tertiary: '#9DB7BE',
        contrast: COLORS.strongBlack[50],
        main: COLORS.innovativeBlue[600],
        supplementaryLight: '#C5E5EE',
      },
      MSRA: {
        primary: '#6840D9',
        light: '#BD94FF',
        extraLight: '#C7CCFF',
        contrast: COLORS.strongBlack[50],
        background: '#271E3A',
      },
      AMS: {
        extraLight: '#FFB2EA',
        light: '#ECA59D',
        primary: '#ED8478',
        background: '#332928',
        contrast: COLORS.strongBlack[50],
        pink: '#E280C8',
        secondary: '#8F6B67',
        tertiary: '#EFB3AC',
      },
      INTERVIEW: {
        extraLight: '#F1C1AC',
        light: '#F1B55B',
        primary: '#F07D3D',
        background: '#322D1E',
        contrast: '#F9FAFA',
        secondary: '#C88B71',
        tertiary: '#F07A38',
      },
      PLAB: {
        extraLight: '#F2C9EA',
        light: '#D194EA',
        primary: '#B35B9E',
        background: '#1F0A1F',
        contrast: '#F9FAFA',
      },
      inactive: {
        light: '#D0D2D7',
        primary: '#A9ACB7',
      },
    },
    calculator: {
      borderColor: COLORS.strongBlack[700],
      result: COLORS.strongBlack[700],
      numbers: COLORS.strongBlack[900],
      numbersHover: COLORS.strongBlack[700],
      numbersActive: alpha(COLORS.strongBlack[200], 0.6),
      functions: COLORS.innovativeBlue[600],
      functionsHover: COLORS.innovativeBlue[700],
      functionsActive: COLORS.innovativeBlue[700],
      utils: COLORS.innovativeBlue[400],
      utilsHover: COLORS.innovativeBlue[500],
      utilsActive: alpha(COLORS.strongBlack[500], 0.6),
      contrastText: WHITE,
      contrastFunctionsText: WHITE,
    },
    image: {
      main: WHITE,
    },
  },
  typography: { ...TYPOGRAPHY },
  elevation: { ...ELEVATION },
  components: {
    MuiCssBaseline: {
      styleOverrides: FONT_FAMILY,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
  },
});
